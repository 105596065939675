// eslint-disable
// this is an auto generated file. This will be overwritten
export const createScheduleEvent = `mutation CreateScheduleEvent($input: ScheduleEventInput!) {
  createScheduleEvent(input: $input) {
    id
    InstanceId
    Queues
    startTime
    endTime
    Name
    isRecurring
    isMultipleDays
    tts
    isAllDayStart
    isAllDayEnd
  }
}
`;

export const updateLivelookContacts = `mutation updateLivelookContacts($input: LiveLookContactInput!) {
  updateLivelookContacts(input: $input) {
    id
    Action
    PreviousContactId
    InstanceId
    QueueName
    QueueArn
    EnqueueTimestamp
    Channel
    CustomerEndpoint
    CustomerName
    CustomerId
    Validated
  }
}
`;

export const callConnectApi = `mutation callConnectApi($input: ConnectInput!) {
  callConnectApi(input: $input) {
    response
  }
}
`;

export const createCustomCRM = `mutation createCustomCRM($input : CustomCRMInput!) {
  createCustomCRM(input: $input) {
    id
    AuthenticationType
    SubmitEndpointUrl
    CancelEndpointUrl
    Username
    Password
    AppKey {
      name
      value
    }
  }
}`;

export const deleteCustomCRM = `mutation createCustomCRM($input : CustomCRMInput!) {
  deleteCustomCRM(input: $input) {
    id
    AuthenticationType
    SubmitEndpointUrl
    CancelEndpointUrl
    Username
    Password
    AppKey {
      name
      value
    }
  }
}`;

export const updateCustomCRM = `mutation updateCustomCRM($input : CustomCRMInput!) {
  updateCustomCRM(input: $input) {
    id
    AuthenticationType
    SubmitEndpointUrl
    CancelEndpointUrl
    Username
    Password
    AppKey {
      name
      value
    }
  }
}`;

export const createReport = `mutation CreateReport($input: ReportInput!, $token: String!) {
  createReport(input: $input, token: $token) {
    id
    Owner
    Type
    InstanceId
    RelativeTime
    Parent
    NextRunTime
    ParentId
    CreatedAt
    RunNow
    CompletedAt
    Units
    Name
    RequestedAt
    ChildReports {
      id
      CompletedAt
      Status
      Language
      LocationXlsx
      Location
      Owner
      DateRange
      Filters
    }
    Status
    Data
    Language
    Filters
    DateRange
    Scheduled
    Schedule {
      Frequency
      DeliveryEmails
    }
  }
}`;

export const updateReport = `mutation updateReport($input: ReportInput!) {
  updateReport(input: $input) {
    id
    Owner
    InstanceId
    Error
    RelativeTime
    NextRunTime
    Units
    ChildReports {
      id
      CompletedAt
      Status
      Name
      Language
      LocationXlsx
      Location
      Owner
      DateRange
    }
    Parent
    ParentId
    CompletedAt
    Scheduled
    Schedule {
      Frequency
      DeliveryEmails
    }
    RunNow
    CreatedAt
    Name
    RequestedAt
    Status
    Type
    Language
    Data
    Filters
    DateRange
  }
}`;

export const deleteScheduleEvent = `mutation DeleteScheduleEvent($input: ScheduleEventInput!) {
  deleteScheduleEvent(input: $input) {
    id
    InstanceId
    Queues
    startTime
    endTime
    Name
    isRecurring
    isMultipleDays
    tts
    isAllDayStart
    isAllDayEnd
  }
}
`;

export const createMonitor = `mutation CreateMonitor($input: MonitorInput!) {
  createMonitor(input: $input) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const setupVoiceCall = `mutation SetupVoiceCall($input: VoiceCallInput!) {
  setupVoiceCall(input: $input) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const createDisposition = `mutation CreateDisposition($input: DispositionInput!) {
  createDisposition(input: $input) {
    id
    InstanceId
    Queues {
      id
      Arn
      Name
    }
    Name
  }
}
`;

export const createLevelList = `mutation createLevelList($input: LevelListInput) {
  createLevelList(input: $input) {
    id
    InstanceId
    Name
    SeriesId
    ListType
    Level
    Queues {
      id
      Arn
      Name
    }
    ParentName
    ParentId
    CTRName
    CTRValue
    ItemName
    Status
  }
}`;

export const updateLevelList = `mutation updateLevelList($input: LevelListInput) {
  updateLevelList(input: $input) {
    id
    InstanceId
    Name
    SeriesId
    ListType
    Level
    Queues {
      id
      Arn
      Name
    }
    ParentName
    ParentId
    CTRName
    CTRValue
    ItemName
    Status
  }
}`;

export const deleteLevelList = `mutation deleteLevelList($input: LevelListInput) {
  deleteLevelList(input: $input) {
    id
    InstanceId
    Name
    SeriesId
    ListType
    Level
    Queues {
      id
      Arn
      Name
    }
    ParentName
    ParentId
    CTRName
    CTRValue
    ItemName
    Status
  }
}`;

export const createSmsContact = `mutation CreateSmsContact($input: SmsContactInput!) {
  createSmsContact(input: $input) {
    InstanceId
    id
    From
    To
    IsActive
    Username
    CreatedAt
    MatchedQueueArn
    MatchedQueueName
    Messages {
      InstanceId
      id
      ContactId
      Author
      Body
      MediaUrls {
        ContentType
        Url
      }
      SmsStatus
      NumMedia
      NumSegments
      SmsMessageSid
      MessageSid
      Direction
      CreatedAt
    }
  }
}
`;

export const smsContactStatusChange = `mutation SmsContactStatusChange($input: SmsContactInput!) {
  smsContactStatusChange(input: $input) {
    InstanceId
    id
    From
    To
    IsActive
    Username
    CreatedAt
    MatchedQueueArn
    Status
  }
}
`;

export const createSmsMessage = `mutation CreateSmsMessage($input: SmsMessageInput!) {
  createSmsMessage(input: $input) {
    InstanceId
    id
    ContactId
    Author
    Body
    MediaUrls {
        ContentType
        Url
    }
    SmsStatus
    NumMedia
    NumSegments
    SmsMessageSid
    MessageSid
    Direction
    CreatedAt
  }
}
`;
export const createSmsConversation = `mutation CreateSmsConversation($input: CreateConversationInput!) {
  createSmsConversation(input: $input) {
    InstanceId
    id
    From
    To
    IsActive
    Username
    CreatedAt
    Messages {
      InstanceId
      id
      ContactId
      Author
      Body
      MediaUrls {
        ContentType
        Url
      }
      SmsStatus
      NumMedia
      NumSegments
      SmsMessageSid
      MessageSid
      Direction
      CreatedAt
    }
  }
}
`;
export const endSmsConversation = `mutation EndSmsConversation($input: EndConversationInput!) {
  endSmsConversation(input: $input) {
    InstanceId
    id
    From
    To
    IsActive
    Username
    CreatedAt
    Ended
    EndedAt
    Messages {
      InstanceId
      id
      ContactId
      Author
      Body
      MediaUrls {
        ContentType
        Url
      }
      SmsStatus
      NumMedia
      NumSegments
      SmsMessageSid
      MessageSid
      Direction
      CreatedAt
    }
  }
}
`;

export const sendTestSmsMessage = `mutation SendTestSmsMessage($input: TestSmsMessageInput!) {
  sendTestSmsMessage(input: $input) {
    InstanceId
    id
    Body
    MessageSid
    CreatedAt
  }
}
`;

export const deleteDisposition = `mutation DeleteDisposition($input: DispositionInput!) {
  deleteDisposition(input: $input) {
    id
    InstanceId
    Queues {
      id
      Arn
      Name
    }
  }
}
`;

export const updateMonitor = `mutation UpdateMonitor($input: MonitorInput!) {
  updateMonitor(input: $input) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const updateVoiceCall = `mutation updateVoiceCall($input: VoiceCallInput!) {
  updateVoiceCall(input: $input) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const deleteMonitor = `mutation DeleteMonitor($input: MonitorInput!) {
  deleteMonitor(input: $input) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const buyPhoneNumber = `mutation buyPhoneNumber($input: PhoneNumberInput!) {
  buyPhoneNumber ( input: $input ){
    DateCreated
    DateUpdated
    Origin
    id
    AccountSid
    Sid
    IdentitySid
    SmsApplicationSid
    SmsFallbackMethod
    SmsFallbackUrl
    SmsMethod
    SmsUrl
    EmergencyAddressSid
    StatusCallback
    StatusCallbackMethod
    TrunkSid
    Uri
    FriendlyName
    PhoneNumber
    Lata
    Locality
    RateCenter
    AddressRequirements
    IsoCountry
    Beta
    Latitude
    Longitude
    Region
    PostalCode
    Country
    Capabilities {
      voice
      SMS
      MMS
    }
  }
}`;

export const updateOwnedPhoneNumber = `mutation updateOwnedPhoneNumber($input: PhoneNumberInput!) {
  updateOwnedPhoneNumber(input: $input) {
    id
    FriendlyName
    DateCreated
    Capabilities {
      voice
      MMS
      SMS
    }
    PhoneNumber
    QueueARNs
    DefaultQueueARN
    SmsUrl
  }
}`;

export const deleteOwnedPhoneNumber = `mutation deleteOwnedPhoneNumber($input: PhoneNumberInput!) {
  deleteOwnedPhoneNumber(input: $input) {
    id
    PhoneNumber
  }
}`;

export const updateQueue = `mutation UpdateQueue($input: QueueInput!) {
  updateQueue(input: $input) {
    id
    Arn
    Action
    Name
    InstanceId
    Skills {
      id
      Title
      Status
      InstanceId
      Level
    }
    OutboundCallerConfig {
      OutboundCallerIdName
      OutboundCallerIdNumberId
      OutboundFlowId
    }
    Open
    Status
    Description
    HoursOfOperationId
    QueueId
    QueueArn
    MaxContacts
    Tags
    Channel
    Contacts {
      InitiationTimestamp
      id
      InitiationMethod
      CustomerEndpoint
      Channel
      Queue
    }
    ReportRange {
      end
      start
    }
    AgentPerformance {
      AvgACW
      AHT
      Holds
      HoldDuration
      HandledCallbacks
      LongestHold
      HandledIn
      HandledOut
      Missed
      Username
    }
    Performance {
      Abandoned
      AvgACW
      AHT
      Holds
      HoldDuration
      HandledCallbacks
      LongestHold
      HandledIn
      HandledOut
      Missed
      Name
    }
    TimeSeriesData {
      AvgWaitTime
      AvgHandlingTime
      AvgAbandonTime
      AvgWrapTime
      AvgHoldTime
      OccupancyPct
      MaxWaitTime
      ContactsMissed
      AbandonedPct
      ServiceLvlPct
      ContactsQueued
      ContactsHandled
      ContactsHandledIn
      ContactsHandledCallbacks
      ContactsHandledOut
      ContactsAbandoned
      ContactsHoldAbandoned
      ContactsInQueueVoice
      ContactsInQueueChat
      ContactsInQueueEmail
      ContactsInQueueCallbacks
      ContactsInQueueSms
      ContactsTransferedExternally
      ServiceLevel_10
      ServiceLevel_15
      ServiceLevel_20
      ServiceLevel_30
      ServiceLevel_60
      ServiceLevel_90
      ServiceLevel_120
      ServiceLevel_180
      ServiceLevel_240
      ServiceLevel_300
    }
    DataSnapshotTime
    NewMetricData {
      AvgWaitTime
      AvgHandlingTime
      AvgAbandonTime
      AvgWrapTime
      AvgHoldTime
      OccupancyPct
      MaxWaitTime
      ContactsMissed
      AbandonedPct
      ServiceLvlPct
      ContactsQueued
      ContactsHandled
      ContactsHandledIn
      ContactsHandledCallbacks
      ContactsHandledOut
      ContactsAbandoned
      ContactsHoldAbandoned
      ContactsInQueueVoice
      ContactsInQueueChat
      ContactsInQueueEmail
      ContactsInQueueCallbacks
      ContactsInQueueSms
      ContactsTransferedExternally
      ServiceLevel_10
      ServiceLevel_15
      ServiceLevel_20
      ServiceLevel_30
      ServiceLevel_60
      ServiceLevel_90
      ServiceLevel_120
      ServiceLevel_180
      ServiceLevel_240
      ServiceLevel_300
    }
  }
}`;

export const updateScheduleEvent = `mutation UpdateScheduleEvent($input: ScheduleEventInput!) {
  updateScheduleEvent(input: $input) {
    id
    InstanceId
    Queues
    startTime
    endTime
    Name
    isRecurring
    isMultipleDays
    tts
    isAllDayStart
    isAllDayEnd
  }
}
`;

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    AWSAccountID
    Password
    InstanceId
    InstanceAlias
    isConnectedToWebRTCCall
    Id
    Arn
    FirstName
    LastName
    Region
    Username
    StatusName
    Email
    StatusDuration
    Role
    Roles
    Permissions
    SecurityProfileId
    IdentityInfo {
      FirstName
      LastName
      Email
    }
    PhoneConfig {
      PhoneType
      AutoAccept
      AfterContactWorkTimeLimit
      DeskPhoneNumber
    }
    DirectoryUserId
    SecurityProfileIds
    RoutingProfileId {
      Name
      Arn
      Id
    }
    Preferences {
      FavoriteTabName
      Avatar
      JabraGen
      ColumnArrangments {
        Type
        Data
      }
      PrimaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      TeamStatusView {
        OfflineHidden
        MinimizeRecent
        MinimizeFavorites
        isOpen
        showAllUsers
      }
      AlertTone
      DeviceId
      Theme
      DashboardCards {
        colOne {
          component
          id
          isShow
          name
        }
        colTwo {
          component
          id
          isShow
          name
        }
        colThree {
          component
          id
          isShow
          name
        }
      }
    }
  }
}
`;

export const destroyVoiceCall =
  /* Sample:
{
  "input": {
    "InstanceId": "1bb4d260-75cf-4036-a5d2-879bc1ec30e8",
    "id": "003ab195-3419-480f-b1c8-2ad22a7d7d21"
  }
}
*/

  `mutation DestroyVoiceCall($input: VoiceCallInput!) {
  destroyVoiceCall(input: $input) {
    id
    InstanceId
  }
}`;

export const toggleRecording = `mutation toggleRecording($input: ToggleRecordingInput) {
    toggleRecording(input: $input) {
      ContactId
      InitialContactId
      Recording
  }
}`;

export const createAccount = `mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    AWSAccountID
    AccessKeyId
    SecretAccessKey
    InstanceIds {
      CcpUrl
      isValid
      id
      Users {
        AWSAccountID
        Password
        InstanceId
        InstanceAlias
        Id
        Arn
        FirstName
        LastName
        Username
        StatusName
        Email
        StatusDuration
        Role
        Permissions
        IdentityInfo {
          FirstName
          LastName
          Email
        }
        PhoneConfig {
          PhoneType
          AutoAccept
          AfterContactWorkTimeLimit
          DeskPhoneNumber
        }
        DirectoryUserId
        SecurityProfileIds
        RoutingProfileId {
          Name
          Arn
          Id
        }
        Preferences {
          Avatar
          JabraGen
          ColumnArrangments {
            Type
            Data
          }
          PrimaryRinger {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          SecondaryRinger {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          PrimaryRingerPeer {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          SecondaryRingerPeer {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          PrimaryRingerEmail {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          SecondaryRingerEmail {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          PrimaryRingerSMS {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          SecondaryRingerSMS {
            Sound
            isEnabled
            DeviceId
            Volume
          }
          AlertTone
          DeviceId
          Theme
          DashboardCards {
            Id
            Name
            Component
            IsShow
          }
        }
      }
      Queues {
        Id
        Arn
        Channel
        NewMetricData {
          Metric {
            Name
            Unit
            Statistic
          }
          Value
        }
      }
    }
    Users {
      AWSAccountID
      Password
      InstanceId
      InstanceAlias
      Id
      Arn
      FirstName
      LastName
      Username
      StatusName
      Email
      StatusDuration
      Role
      Permissions
      IdentityInfo {
        FirstName
        LastName
        Email
      }
      PhoneConfig {
        PhoneType
        AutoAccept
        AfterContactWorkTimeLimit
        DeskPhoneNumber
      }
      DirectoryUserId
      SecurityProfileIds
      RoutingProfileId {
        Name
        Arn
        Id
      }
      Preferences {
        FavoriteTabName
        Avatar
        JabraGen
        PrimaryRinger {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRinger {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerPeer {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerPeer {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerEmail {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerEmail {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerSMS {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerSMS {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        AlertTone
        DeviceId
        Theme
        DashboardCards {
          Id
          Name
          Component
          IsShow
        }
      }
    }
    Queues {
      Id
      Arn
      Channel
      NewMetricData {
        Metric {
          Name
          Unit
          Statistic
        }
        Value
      }
    }
    Creds {
      Account
      Access
      Secret
    }
  }
}
`;

export const removeQueuedCall = `mutation removeQueuedCall($input: UpdateQueuedCallsInput!) {
  removeQueuedCall(input: $input) {
    id
    Contacts {
      id
      Channel
      InitiationMethod
      InitiationTimestamp
      CustomerEndpoint
    }
  }
}`;

export const insertQueuedCall = `mutation insertQueuedCall($input: UpdateQueuedCallsInput!) {
  insertQueuedCall(input: $input) {
    id
    Contacts {
      Queue
      id
      Channel
      InitiationMethod
      InitiationTimestamp
      CustomerEndpoint
    }
  }
}`;
export const updateQueuedCall = `mutation insertQueuedCall($input: UpdateQueuedCallsInput!) {
  updateQueuedCall(input: $input) {
    id
    Contacts {
      Queue
      id
      Channel
      InitiationMethod
      InitiationTimestamp
      CustomerEndpoint
    }
  }
}`;

export const getQueueMetricsDone = `mutation getQueueMetricsDone($input: QueueConnectionInput!) {
  getQueueMetricsDone(input: $input) {
    InstanceId
    queuesCheckSum
    items {
      id
      Arn
      Name
      Channel
      OutboundCallerConfig {
        OutboundCallerIdName
        OutboundCallerIdNumberId
        OutboundFlowId
      }
      Skills {
        id
        Title
        Status
        InstanceId
        Level
      }
      Status
      Open
      Description
      HoursOfOperationId
      QueueId
      QueueArn
      MaxContacts
      Tags
      ReportRange {
        start
        end
      }
      CsatPerformance {
        SurveyNames
        TotalSurveys
        TotalResponses
        Results
      }
      Performance {
        Name
        Status
        Open
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Abandoned
      }
      DataSnapshotTime
      TimeSeriesInterval
      NewMetricData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
        AgentsAfterContactWork
        AgentsAvailable
        AgentsError
        AgentsNonProductive
        AgentsOnCall
        AgentsOnContact
        AgentsOnline
        AgentsStaffed
        ContactsInQueue
        ContactsScheduled
      }
    }
    summary {
      Name
      Arn
      id
      TimeSeriesData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
      }
      NewMetricData {
        AvgWaitTime
        AvgHandlingTime
        AvgAbandonTime
        AvgWrapTime
        AvgHoldTime
        OccupancyPct
        MaxWaitTime
        ContactsMissed
        AbandonedPct
        ServiceLvlPct
        ContactsQueued
        ContactsHandled
        ContactsHandledIn
        ContactsHandledCallbacks
        ContactsHandledOut
        ContactsAbandoned
        ContactsHoldAbandoned
        ContactsInQueueVoice
        ContactsInQueueChat
        ContactsInQueueEmail
        ContactsInQueueCallbacks
        ContactsInQueueSms
        ContactsTransferedExternally
        ServiceLevel_10
        ServiceLevel_15
        ServiceLevel_20
        ServiceLevel_30
        ServiceLevel_60
        ServiceLevel_90
        ServiceLevel_120
        ServiceLevel_180
        ServiceLevel_240
        ServiceLevel_300
        AgentsAfterContactWork
        AgentsAvailable
        AgentsError
        AgentsNonProductive
        AgentsOnCall
        AgentsOnContact
        AgentsOnline
        AgentsStaffed
        ContactsInQueue
        ContactsScheduled
      }
      CsatPerformance {
        SurveyNames
        TotalSurveys
        TotalResponses
        Results
      }
      Performance {
        Name
        Status
        Open
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Abandoned
      }
      AgentPerformance {
        AvgACW
        AHT
        Holds
        HoldDuration
        HandledCallbacks
        LongestHold
        HandledIn
        HandledOut
        HandledSms
        HandledEmail
        HandledChat
        Missed
        Username
      }
      Contacts {
        InitiationTimestamp
        id
        InitiationMethod
        CustomerEndpoint
        Channel
        Queue
      }
    }
  }
}`;

export const createSecurityProfile = `mutation createSecurityProfile($input: SecurityProfileInput!) {
  createSecurityProfile(input: $input) {
    id
    Name
    Description
    Realm
    SoftPhone
    PermissionGroups {
      Name
      Order
      PermissionItems {
        Action
        Name
        Values {
          All
          View
          Edit
          Create
          Remove
          Listen
          Download
          Inbound
          Outbound
          Enable
          Share
        }
      }
    }
  }
}`;

export const deleteSecurityProfile = `mutation deleteSecurityProfile($input: SecurityProfileInput!) {
  deleteSecurityProfile(input: $input) {
    id
    Name
    Description
    SoftPhone
    PermissionGroups {
      Name
      Order
      PermissionItems {
        Action
        Name
        Values {
          All
          View
          Edit
          Create
          Remove
          Listen
          Download
          Inbound
          Outbound
          Enable
          Share
        }
      }
    }
  }
}`;

export const updateSecurityProfile = `mutation updateSecurityProfile($input: SecurityProfileInput!) {
  updateSecurityProfile(input: $input) {
    InstanceId
    id
    Realm
    Name
    Description
    isDefault
    SoftPhone
    PermissionGroups {
      Name
      Order
      PermissionItems {
        Action
        Name
        Values {
          All
          View
          Edit
          Create
          Remove
          Listen
          Download
          Inbound
          Outbound
          Enable
          Share
        }
      }
    }
  }
}`;

export const updateUserSecurityProfile = `mutation updateUserSecurityProfile ($input: UpdateUserSecurityProfileInput!) {
  updateUserSecurityProfile(input: $input) {
    InstanceId
    Username
    SecurityProfile {
      id
      Name
      Description
      SoftPhone
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
  }
}
`;

export const newPeerCandidate = `mutation newPeerCandidate($input: CandidateInfoInput!) {
  newPeerCandidate(input: $input) {
    ConversationId
    Candidate {
      candidate
      sdpMLineIndex
      sdpMid
    }
  }
}`;

export const newInitiatorCandidate = `mutation newInitiatorCandidate($input: CandidateInfoInput!) {
  newInitiatorCandidate(input: $input) {
    ConversationId
    Candidate {
      candidate
      sdpMLineIndex
      sdpMid
    }
  }
}`;

export const updateAccount = `mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    InstanceId
    AdminLogo
		LogoMediaURL
    AdminName
    AdminMail
    CompanyName
    CognitoPoolId
    AWSAccountID
    InstanceAlias
    Region
    AccessKeyId
    RoleArn
    SecretAccessKey
    LastCtrEvent
    LastAgentEvent
    LastContactEvent
    DomainName
    BucketName
    TimeZone {
      value
      abbr
      isdst
      text
      offset
      utc
    }
  }
}
`;

export const needsHelp = `mutation NeedsHelp($input: UpdateUserInput!) {
  needsHelp(input: $input) {
    NeedsHelp
    InstanceId
    Username
    Id
  }
}`;

export const heartbeat = `mutation heartbeat($input: UpdateUserInput!) {
  heartbeat(input: $input) {
    Platform
    isBeingCoached
    isBeingMonitored
    AWSAccountID
    Password
    InstanceId
    isConnectedToWebRTCCall
    InstanceAlias
    Id
    Arn
    FirstName
    FavoriteTeamMembers
    Region
    LastName
    Username
    Contact
    Contacts
    StatusName
    Email
    StatusDuration
    StatusStartTimestamp
    StatusType
    NeedsHelp
    Role
    Roles
    Permissions
    SecurityProfileId
    HeartBeat
    SecurityProfile {
      id
      Name
      Description
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
    IdentityInfo {
      FirstName
      LastName
      Email
    }
    PhoneConfig {
      PhoneType
      AutoAccept
      AfterContactWorkTimeLimit
      DeskPhoneNumber
      DefaultCountry {
        Name
        ISO
      }
    }
    DirectoryUserId
    SecurityProfileIds
    RoutingProfileId {
      Name
      Arn
      Id
    }
    Preferences {
      TeamStatusView {
        OfflineHidden
        MinimizeRecent
        MinimizeFavorites
        isOpen
        showAllUsers
      }
      Avatar
      JabraGen
      ColumnArrangments {
        Type
        Data
      }
      PrimaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      AlertTone
      AgentCallTone
      AgentMessageTone
      DeviceId
      Theme
      DashboardCards {
        colOne {
          component
          id
          isShow
          name
        }
        colTwo {
          component
          id
          isShow
          name
        }
        colThree {
          component
          id
          isShow
          name
        }
      }
    }
    BackendVersion
  }
}`;

export const createWebhookChannel = `mutation createWebhookChannel($input: WebhookChannelInput!) {
  createWebhookChannel(input: $input) {
    WebhookProvider
    WebhookType
    CreatedAt
    Description
    FlowId
    InstanceId
    Name
    id
    WebhookUrl
    TwilioAccountId
    TwilioApiKey
    TwilioApiSecret
    MessageMediaApiKey
    MessageMediaApiSecret
    SmtpHost
    SmtpUserName
    SmtpPass
    SmtpPort
    SmtpUseTls
    LambdaName
    LambdaArn
    SesRegion
    SesBucketName
    CustomAttributes {
      Name
      Value
      Type
    }
    TaskName {
      Name
      Value
      Type
    }
    TaskDescription {
      Name
      Value
      Type
    }
    ReferenceUrl {
      Name
      Value
      Type
    }
    ProviderPhoneNumber
    ProviderEmail
		OAuthProvider
    InboundEmailAddress
    OutboundEmailAddress
  }
}
`;

export const updateWebhookChannel = `mutation updateWebhookChannel($input: UpdateWebhookChannelInput!) {
  updateWebhookChannel(input: $input) {
    InstanceId
    id
    FlowId
    CreatedAt
    WebhookUrl
    TwilioAccountId
    TwilioApiKey
    TwilioApiSecret
    MessageMediaApiKey
    InitialResponse
    FinalResponse
    MessageMediaApiSecret
    Name
    Description
    WebhookProvider
    WebhookType
    SmtpHost
    SmtpUserName
    SmtpPass
    SmtpPort
    SmtpUseTls
    LambdaName
    LambdaArn
    SesRegion
    SesBucketName
    CustomAttributes {
      Name
      Value
      Type
    }
    TaskName {
      Name
      Value
      Type
    }
    TaskDescription {
      Name
      Value
      Type
    }
    ReferenceUrl {
      Name
      Value
      Type
    }
    ProviderPhoneNumber
    ProviderEmail
		OAuthProvider
    StateMachineArn
    TemplateName
    UserCredentials {
      DisplayName
      Name
      Password
    }
    InboundEmailAddress
    OutboundEmailAddress
  }
}
`;

export const deleteWebhookChannel = `mutation deleteWebhookChannel($input: DeleteWebhookChannelInput!) {
  deleteWebhookChannel(input: $input) {
    WebhookProvider
    WebhookType
    CreatedAt
    Description
    FlowId
    InstanceId
    Name
    id
    WebhookUrl
  }
}
`;

export const putUserStatus = `mutation putUserStatus($input: PutUserStatusInput!) {
  putUserStatus(input: $input) {
    InstanceId
  }
}
`;

export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    Platform
    isBeingCoached
    isBeingMonitored
    AWSAccountID
    Password
    InstanceId
    isConnectedToWebRTCCall
    StatusStartTimestamp
    InstanceAlias
    Id
    Arn
    FirstName
    CivicAddress
    FavoriteTeamMembers
    Region
    LastName
    Username
    Contact
    Contacts
    StatusName
    Email
    StatusDuration
    StatusType
    NeedsHelp
    Role
    Roles
    Permissions
    SecurityProfileId
    HeartBeat
    SecurityProfile {
      id
      Name
      Description
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
    IdentityInfo {
      FirstName
      LastName
      Email
    }
    PhoneConfig {
      PhoneType
      AutoAccept
      AfterContactWorkTimeLimit
      DeskPhoneNumber
      DefaultCountry {
        Name
        ISO
      }
    }
    DirectoryUserId
    SecurityProfileIds
    RoutingProfileId {
      Name
      Arn
      Id
    }
    Preferences {
      Language
      FavoriteTabName
      TeamStatusView {
        OfflineHidden
        MinimizeRecent
        MinimizeFavorites
        isOpen
        showAllUsers
      }
      Avatar
      JabraGen
      ColumnArrangments {
        Type
        Data
      }
      CallEndTone
      PrimaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerPeer {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      AlertTone
      AgentCallTone
      AgentMessageTone
      DeviceId
      Theme
      IsEnabledGoToApplication
      DashboardCards {
        colOne {
          component
          id
          isShow
          name
        }
        colTwo {
          component
          id
          isShow
          name
        }
        colThree {
          component
          id
          isShow
          name
        }
      }
    }
  }
}
`;

export const updateUsers = `mutation UpdateUsers($input: BulkUserUpdateInput!) {
  updateUsers(input: $input) {
    InstanceId
    items {
      Platform
      isBeingCoached
      isBeingMonitored
      AWSAccountID
      Password
      InstanceId
      isConnectedToWebRTCCall
      StatusStartTimestamp
      InstanceAlias
      Id
      Arn
      FirstName
      FavoriteTeamMembers
      Region
      LastName
      Username
      Contact
      Contacts
      StatusName
      Email
      StatusDuration
      StatusType
      NeedsHelp
      Role
      Roles
      Permissions
      SecurityProfileId
      HeartBeat
      SecurityProfile {
        id
        Name
        Description
        PermissionGroups {
          Name
          Order
          PermissionItems {
            Action
            Name
            Values {
              All
              View
              Edit
              Create
              Remove
              Listen
              Download
              Inbound
              Outbound
              Enable
              Share
            }
          }
        }
      }
      IdentityInfo {
        FirstName
        LastName
        Email
      }
      PhoneConfig {
        PhoneType
        AutoAccept
        AfterContactWorkTimeLimit
        DeskPhoneNumber
      }
      DirectoryUserId
      SecurityProfileIds
      Queues
      RoutingProfileId {
        Name
        Arn
        Id
      }
      Preferences {
        TeamStatusView {
          OfflineHidden
          MinimizeRecent
          MinimizeFavorites
          isOpen
          showAllUsers
        }
        Avatar
        JabraGen
        ColumnArrangments {
          Type
          Data
        }
        PrimaryRinger {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRinger {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerPeer {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerPeer {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerEmail {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerEmail {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        PrimaryRingerSMS {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        SecondaryRingerSMS {
          Sound
          isEnabled
          DeviceId
          Volume
        }
        AlertTone
        AgentCallTone
        AgentMessageTone
        DeviceId
        Theme
        DashboardCards {
          colOne {
            component
            id
            isShow
            name
          }
          colTwo {
            component
            id
            isShow
            name
          }
          colThree {
            component
            id
            isShow
            name
          }
        }
        FavoriteTabName
      }
    }
  }
}`;

export const createAnnouncement = `mutation CreateAnnouncement($input: AnnouncementInput!) {
    createAnnouncement(input: $input) {
      id
      Message
      InstanceId
      From
    Urgency
    Queues
    Title
    Date
    DeliveryMethods
  }
}`;

export const createConnectUser = `mutation CreateConnectUser($input: CreateUserInput!) {
  createConnectUser(input:$input) {
    Id
    Arn
    InstanceId
    PhoneConfig {
      PhoneType
      AfterContactWorkTimeLimit
      AutoAccept
      DeskPhoneNumber
    }
    RoutingProfileId {
      Id
      Name
      Arn
    }
    Username
    Roles
    SecurityProfile {
      id
      Name
      Description
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
    StatusName
    StatusType
    IdentityInfo {
      FirstName
      LastName
      Email
    }
    SecurityProfileIds
    SecurityProfileId
  }
}`;

export const deleteContact = `mutation DeleteContact($input: DeleteContactInput!) {
  deleteContact(input: $input) {
    InstanceId
    UserId
    Id
    Name
    Email
    WorkPhone
    HomePhone
    CellPhone
    IsGlobal
  }
}
`;
export const createContact = `mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    InstanceId
    UserId
    Id
    Name
    Email
    WorkPhone
    HomePhone
    CellPhone
    IsGlobal
    IsFavorite
  }
}
`;

export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    AWSAccountID
    InstanceId
    Id
    Username
  }
}
`;
export const promoteUser = `mutation PromoteUser($input: CognitoUserInput!) {
  promoteUser(input: $input) {
    Username
    UserPoolId
    GroupName
  }
}
`;

export const createSupportCase = `mutation CreateSupportCase($input: CreateSupportCaseInput!) {
  createSupportCase(input: $input) {
    sys_id
    number
  }
}
`;

export const createActivity = `mutation CreateActivity($input: CreateActivityInput!) {
  createActivity(input: $input) {
    id
    InstanceId
    Username
    AWSAgentID
    AWSAccountID
    Channel
    InitiationMethod
    Source
    CustomerEndpoint
    CustomCustomerEndpoint
    SystemEndpoint
    Source
    InitiationTimestamp
    DisconnectTimestamp
    Queue
    Connections
    range
    DynamicsEntityId
  }
}
`;

export const updateContact = `mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    InstanceId
    UserId
    Id
    Name
    Email
    WorkPhone
    HomePhone
    CellPhone
    IsGlobal
    IsFavorite
  }
}
`;
export const updateDisposition = `mutation UpdateDisposition($input: DispositionInput!) {
  updateDisposition(input: $input) {
    id
    InstanceId
    Queues {
      id
      Arn
      Name
    }
    Name
  }
}
`;

export const deleteReport = `mutation deleteReport($id: String!) {
  deleteReport(id: $id) {
    id
  }
}`;

export const updateActivity = `mutation UpdateActivity($input: UpdateActivityInput!) {
  updateActivity(input: $input) {
    id
    InstanceId
    Username
    AWSAgentID
    AWSAccountID
    Duration
    Channel
    InitiationMethod
    CustomerEndpoint
    CustomCustomerEndpoint
    SystemEndpoint
    CannedScriptUsed
    InitiationTimestamp
    DisconnectTimestamp
    Queue
    Connections
    DynamicsEntityId
    CustomCRM {
      id
      HouseholdName
      ReminderSummary
      Action
    }
  }
}
`;
export const createHealthEvent = `mutation CreateHealthEvent($input: CreateHealthEventInput!) {
  createHealthEvent(input: $input) {
    arn
    service
    eventTypeCode
    eventTypeCategory
    region
    statusCode
    startTime
    endTime
    lastUpdatedTime
    latestDescription
  }
}
`;

export const createAgentChatParticipant = `mutation createAgentChatParticipant($input: AgentChatParticipantInput!) {
  createAgentChatParticipant(input: $input) {
    InstanceId
    Username
    ConversationId
  }
}`;

export const updateSettings = `mutation updateSettings($input: SettingsInput!) {
  updateSettings(input: $input) {
    InstanceId
    Theme
    MultiLevelSeries
    PresentedNotAnswered {
      Status
      Label
      Duration
    }
    CallDispositions {
      isEnabled
      allowCustomInput
      isMandatory
      isMultiLevelMandatory
    }
    ChangeOutboundQueues {
      isEnabled
      Queues
    }
    SAML {
      isEnabled
      loginUrl
    }
    LiveLook {
      isEnabled
    }
    Reporting {
      isEnabled
      isAllowed
    }
    ContactLens {
      isAllowed
      isEnabled
    }
    DefaultCountry {
      Name
      ISO
    }
    OutboundMessages{
      isEnabled
      ContactFlow
    }
    ThirdPartyIntegrations
    DefaultOutboundChannels {
      EmailChannelId
      SmsChannelId
    }
    IdleActive
    IdleStatus
    IdleTime
    LegacyWebhookChannelBehavior
    ComprehendIntegration
    NewStatusBar
    QuickSightReadOnlyUser
    QuickSightPricing
    DefaultTaskTemplateId
    EmailAliases {
      Id
      EmailAddress
    }
    ContactAttributesFilter {
      Id
      ContactAttributeKey
    }
    DefaultContactFlow{
      FlowId
      FlowName
    }
  }
}
`;

export const login = `mutation login($input: UpdateUserInput!) {
  login(input:$input) {
    Username
    InstanceId
    HeartBeat
    Platform,
    Preferences {
      FavoriteTabName
    }
  }
}`;

export const updateFavoriteItems = `mutation updateFavoriteItems ($input: UpdateFavoriteItemsInput!) {
  updateFavoriteItems(input: $input) {
    FavoriteTeamMembers
    FavoriteDirectoryIds
  }
}`;

export const createSmsFlow = `mutation CreateSmsFlow($input: SmsFlowInput!) {
  createSmsFlow(input: $input) {
    id
    Name
    InstanceId
    WebhookIds
    KeywordMap {
      Keywords
      QueueArns
      Position
    }
    DefaultDestination
    InitialResponse
    FinalResponse
    CreatedAt
  }
}
`;

export const updateSmsFlow = `mutation UpdateSmsFlow($input: SmsFlowInput!) {
  updateSmsFlow(input: $input) {
    id
    Name
    InstanceId
    WebhookIds
    KeywordMap {
      Keywords
      QueueArns
      Position
    }
    DefaultDestination
    InitialResponse
    FinalResponse
    CreatedAt
  }
}
`;

export const deleteSmsFlow = `mutation DeleteSmsFlow($input: SmsFlowInput!) {
  deleteSmsFlow(input: $input) {
    id
    Name
    InstanceId
    KeywordMap {
      Keywords
      QueueArns
      Position
    }
    DefaultDestination
    InitialResponse
    FinalResponse
    CreatedAt
  }
}
`;

export const sendConversationMessage = `mutation sendConversationMessage($input: ConversationMessageInput!) {
  sendConversationMessage(input: $input) {
    InstanceId
    id
    ContactId
    MessageId
    To
    Bcc
    From
    Subject
    Cc
    Body
    NumMedia
    BodyUrl
    BodyId
    MediaUrls {
      ContentType
      Url
      Expiration
      Key
    }
    Author
    NumSegments
    Direction
    CreatedAt
    ConversationId
  }
}`;

export const CreateSmsConversation = `mutation CreateSmsConversation($input: CreateConversationInput!) {
  createSmsConversation(input: $input) {
    InstanceId
    id
    ContactId
    Body
    SmsStatus
    Author
    NumMedia
    NumSegments
    SmsMessageSid
    MessageSid
    Direction
    CreatedAt
  }
}`;

export const EndSmsConversation = `mutation EndSmsConversation($input: EndConversationInput!) {
  endSmsConversation(input: $input) {
    InstanceId
    id
    From
    To
    IsActive
    Username
    CreatedAt
    Ended
    EndedAt
    Messages {
      InstanceId
      id
      ContactId
      Body
      SmsStatus
      Author
      NumMedia
      NumSegments
      SmsMessageSid
      MessageSid
      Direction
      CreatedAt
    }
  }
}`;

export const CreateAgentChatConversation = `mutation CreateAgentChatConversation($input: AgentChatConversationInput!) {
  createAgentChatConversation(input: $input) {
    InstanceId
    id
    Messages {
      Author
      id
      Body
      MessageError
      MessageStatus
      ContentType
      CreatedAt
      UpdatedAt
      EventType
    }
    Participants {
      Username
      ConversationId
      IsTyping
    }
    EventType
  }
}`;

export const CreateAgentChatMessage = `mutation CreateAgentChatMessage($input: AgentChatMessageInput!) {
  createAgentChatMessage(input: $input) {
    InstanceId
    Author
    id
    ConversationId
    Body
    SentAt
    MessageError
    MessageStatus
    ContentType
    CreatedAt
    UpdatedAt
    EventType
    Receiver
  }
}`;

export const createEmailServerConfig = `mutation CreateEmailServerConfig($input: EmailServerConfigInput) {
  createEmailServerConfig(input: $input) {
    id
    ImapHost
    ImapUserName
    ImapPort
    ImapUseTls
    ImapPass
    SmtpHost
    SmtpUserName
    SmtpPort
    SmtpPass
    IsAssignedToFlow
    error {
      id
      type
      message
    }
  }
}`;

export const updateEmailServerConfig = `mutation UpdateEmailServerConfig($input: UpdateEmailServerConfigInput) {
  updateEmailServerConfig(input: $input) {
    id
    InstanceId
    ImapHost
    ImapUserName
    ImapPass
    ImapPort
    ImapUseTls
    SmtpHost
    SmtpUserName
    SmtpPass
    SmtpPort
    IsAssignedToFlow
    error {
      id
      type
      message
    }
  }
}`;

export const deleteEmailServerConfig = `mutation deleteEmailServerConfig($input: UpdateEmailServerConfigInput) {
  deleteEmailServerConfig(input: $input) {
    id
  }
}`;

export const acceptEmailContact = `mutation AcceptEmailContact($input: AcceptEmailInput!) {
  acceptEmailContact(input: $input) {
    id
    IsActive
    InstanceId
    AgentEmail
    EmailFlowId
    Username
    CustomerEmail
    CreatedAt
    Accepted
    AcceptedAt
    RejectedAt
  }
}`;

export const createEmailFlow = `mutation CreateEmailFlow($input: EmailFlowInput!) {
  createEmailFlow(input: $input) {
    id
    Name
    InstanceId
    EmailServerConfigId
    KeywordMap {
      Keywords
      QueueArns
      Position
    }
    DefaultDestination
    CreatedAt
    InitialResponse
    FinalResponse
  }
}`;

export const deleteEmailFlow = `mutation DeleteEmailFlow($input: EmailFlowInput!) {
  deleteEmailFlow(input: $input) {
    id
  }
}`;

export const endEmailConversation = `mutation endEmailConversation($input: EndConversationInput) {
  endEmailConversation(input: $input) {
    id
  }
}`;

export const updateEmailFlow = `mutation UpdateEmailFlow($input: EmailFlowInput!) {
  updateEmailFlow(input: $input) {
    id
    Name
    InstanceId
    EmailServerConfigId
    KeywordMap {
      Keywords
      QueueArns
      Position
    }
    DefaultDestination
    CreatedAt
    InitialResponse
    FinalResponse
  }
}
`;
export const UpdateAgentChatMessage = `mutation UpdateAgentChatMessage($input: AgentChatMessageInput!) {
  updateAgentChatMessage(input: $input) {
    InstanceId
    Author
    id
    ConversationId
    Body
    MessageError
    MessageStatus
    ContentType
    CreatedAt
    UpdatedAt
    SentAt
    EventType
  }
}`;

export const MarkAllMessagesRead = `mutation MarkAllMessagesRead($input: AgentChatMessageInput!){
  markAllMessagesRead(input: $input){
    items {
      InstanceId
      ConversationId
      Body
      MessageStatus
    }
  }
}`;

export const DeleteAgentChatMessage = `mutation DeleteAgentChatMessage($input: AgentChatMessageInput!) {
  deleteAgentChatMessage(input: $input) {
    InstanceId
    Author
    id
    ConversationId
    Body
    MessageError
    MessageStatus
    ContentType
    CreatedAt
    UpdatedAt
    EventType
    Receiver
  }
}`;

export const AcceptSmsConversation = `mutation acceptSmsConversation($input: AcceptSmsInput!) {
  acceptSmsConversation(input: $input) {
    id
    IsActive
    InstanceId
  }
}`;

export const closeConversation = `mutation closeConversation($ContactId: String!, $SendFinalResponse: Boolean!) {
  closeConversation(ContactId: $ContactId, SendFinalResponse: $SendFinalResponse) {
    id
    IsActive
    Status
    ContactId
  }
}`;

export const assignConversation = `mutation assignConversation($ContactId: String!, $Username: String!) {
  assignConversation(ContactId: $ContactId, Username: $Username) {
    id
    IsActive
    Status
    ContactId
  }
}`;

export const createActivityNote = `mutation createActivityNote($input: ActivityNoteInput!) {
  createActivityNote(input: $input) {
    id
    Title
    Body
    ContactId
    Agent
    CustomerEndpoint
    CreatedAt
  }
}`;

export const deleteAnnouncement = `mutation DeleteAnnouncement($input: DeleteAnnouncementInput!) {
  deleteAnnouncement(input: $input) {
    id
    Message
    InstanceId
    From
    Urgency
    Queues
    Title
    Date
  }
}`;

export const createSkill = `mutation createSkill($input: SkillInput) {
  createSkill(input: $input) {
      InstanceId
      Title
      Status
    }
}`;

export const assignSkill = `mutation assignSkill($input: SkillsDataInput) {
  assignSkill(input: $input) {
      InstanceId
      Skills {
        id
        InstanceId
        Title
        Status
        Level
      }
      Queues {
        id
        Arn
        Name
      }
      Users {
        Id
        FirstName
        LastName
        InstanceId
        Username
      }
    }
}`;

export const removeSkill = `mutation removeSkill($input: SkillsDataInput) {
  removeSkill(input: $input) {
      InstanceId
      Skills {
        id
        InstanceId
        Title
        Status
        Level
      }
      SkillIds
      Queues {
        id
        Arn
        Name
      }
      Users {
        Id
        FirstName
        LastName
        InstanceId
        Username
      }
    }
}`;

export const deleteSkill = `mutation deleteSkill($input: SkillInput) {
  deleteSkill(input: $input) {
        id
        InstanceId
        Title
        Status
        Level
  }
}`;

export const createThirdPartyIntegration = `mutation createThirdPartyIntegration($input: ThirdPartyIntegrationCreateInput!) {
  createThirdPartyIntegration(input: $input) {
      message
  }
}`;

export const updateThirdPartyIntegration = `mutation updateThirdPartyIntegration($input: ThirdPartyIntegrationUpdateInput!) {
  updateThirdPartyIntegration(input: $input) {
      message
  }
}`;

export const deleteThirdPartyIntegration = `mutation deleteThirdPartyIntegration($input: ThirdPartyIntegrationDeleteInput!) {
  deleteThirdPartyIntegration(input: $input) {
      message
  }
}`;

export const syncUsers = `mutation SyncUsers($input: syncUsersInput) {
  syncUsers(input: $input) {
    result
  }
}
`;

export const createTranslation = `mutation createTranslation($input: CreateTranslationInput!) {
  createTranslation(input: $input) {
  TranslatedText
  sourceLang
  targetLang
  }
}`;

export const createScript = `mutation createScript($input: ScriptInput) {
  createScript(input: $input){
    id
    InstanceId
    Username
    Title
    Text
    Queues {
      id
      Arn
      Name
    }
    Channels
    Scope
    Role
 }
}`;

export const deleteScript = `mutation deleteScript($input: ScriptInput) {
  deleteScript(input: $input){
    id
    InstanceId
    Username
    Title
    Text
    Queues {
      id
      Arn
      Name
    }
    Channels
    Scope
    Role
  }
}`;

export const updateScript = `mutation updateScript($input: ScriptInput) {
  updateScript(input: $input){
    id
    InstanceId
    Username
    Title
    Text
    Queues {
      id
      Arn
      Name
    }
    Channels
    Scope
    Role
  }
}`;

export const createPublicAPIBearerToken = `mutation createPublicAPIBearerToken($input: PublicAPIBearerTokenInput) {
  createPublicAPIBearerToken(input: $input){
    id
    Title
    Token
    CreatedAt
    InstanceId
    Username
 }
}`;

export const deletePublicAPIBearerToken = `mutation deletePublicAPIBearerToken($input: PublicAPIBearerTokenInput) {
  deletePublicAPIBearerToken(input: $input){
    id
    Title
    Token
    CreatedAt
    InstanceId
    Username
 }
}`;

export const createSurvey = `mutation createSurvey($input: SurveyInput!) {
  createSurvey(input: $input) {
    id
    Name
    Description
    UniqueAttribute
    Questions {
      Text
      Attribute
      Answers {
        Text
        Value
      }
    }
    CreatedAt
    UpdatedAt
    ContactFlowId
  }
}
`;
export const updateSurvey = `mutation updateSurvey($input: SurveyInput!) {
  updateSurvey(input: $input) {
    id
    Name
    Description
    UniqueAttribute
    Questions {
      Text
      Attribute
      Answers {
        Text
        Value
      }
    }
    CreatedAt
    UpdatedAt
    ContactFlowId
  }
}
`;
export const deleteSurvey = `mutation deleteSurvey($input: SurveyInput!) {
  deleteSurvey(input: $input) {
    id
    Name
    Description
    UniqueAttribute
    Questions {
      Text
      Attribute
      Answers {
        Text
        Value
      }
    }
    CreatedAt
    UpdatedAt
  }
}
`;

export const outboundMessages = `mutation outboundMessages($input: ConversationMessageInput!) {
  outboundMessages(input: $input) {
    InstanceId
    id
    ContactId
    MessageId
    To
    Bcc
    From
    Subject
    Cc
    Body
    NumMedia
    BodyUrl
    BodyId
    MediaUrls {
      ContentType
      Url
      Expiration
    }
    Author
    NumSegments
    Direction
    CreatedAt
    ConversationId
    ChannelId
  }
}`;

export const createSmsTroughPinpointIntegration = `mutation createSmsTroughPinpointIntegration($input: SmsTroughPinpointIntegrationInput!) {
  createSmsTroughPinpointIntegration(input: $input) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on SmsTroughPinpointIntegrationType {
                    ContactFlowId
                    PhoneNumber
                    PinpointAppId
                    CloudFormationUrl
                    CloudFormationStackName
            }
        }
    }
}`;

export const updateSmsTroughPinpointIntegration = `mutation updateSmsTroughPinpointIntegration($input: SmsTroughPinpointIntegrationInput!) {
  updateSmsTroughPinpointIntegration(input: $input) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on SmsTroughPinpointIntegrationType {
                    ContactFlowId
                    PhoneNumber
                    PinpointAppId
                    CloudFormationUrl
                    CloudFormationStackName
            }
        }
    }
}`;

export const deleteIntegration = `mutation deleteIntegration($IntegrationId: String!) {
  deleteIntegration(IntegrationId: $IntegrationId) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on SmsTroughPinpointIntegrationType {
                    ContactFlowId
                    PhoneNumber
                    PinpointAppId
                    CloudFormationUrl
                    CloudFormationStackName
            }
            __typename
            ... on Office365IntegrationType {
                ClientSecret
                ClientId
                TenantId
                SMTP
                Teams
                HasDelegatedSession
                EnabledChangeStatusInConnect
                ConnectToTeamsStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
                TeamsToConnectStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
            }
            __typename
            ... on GoogleAuthIntegrationType {
                ClientId
                ClientSecret
                RefreshToken
            }
        }
    }
}`;

export const queueRebalancingTransferUserMove = `mutation queueRebalancingTransferUserMove($input: queueRebalancingTransferUserMoveInput!) {
  queueRebalancingTransferUserMove(input: $input) {
    message
  }
}`;

export const queueRebalancingTransferUserCopy = `mutation queueRebalancingTransferUserCopy($input: queueRebalancingTransferUserCopyInput!) {
  queueRebalancingTransferUserCopy(input: $input) {
    message
  }
}`;

export const createConnectBackupVersion = `mutation createConnectBackupVersion($input: ConnectBackupVersionInput){
    createConnectBackupVersion(input: $input) {
        VersionName
        VersionNumber
        InstanceId
        CreatedAt
        CreatedBy
    }
}`;

export const deleteConnectBackupVersion = `mutation deleteConnectBackupVersion($input: ApplyConnectBackupVersionInput){
    deleteConnectBackupVersion(input: $input) {
        VersionName
        VersionNumber
        InstanceId
        CreatedAt
        CreatedBy
    }
}`;

export const applyConnectBackupVersion = `mutation applyConnectBackupVersion($input: ApplyConnectBackupVersionInput){
    applyConnectBackupVersion(input: $input) {
        Message
    }
}`;

export const enableMonitorContact = `mutation enableMonitorContact($input: MonitorInput!) {
  enableMonitorContact(input: $input) {
       ContactId
       ContactArn
    }
}`;

export const createOffice365Integration = `mutation createOffice365Integration($input: Office365IntegrationInput!) {
  createOffice365Integration(input: $input) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on Office365IntegrationType {
                ClientSecret
                ClientId
                TenantId
                SMTP
                Teams
                HasDelegatedSession
                EnabledChangeStatusInConnect
                ConnectToTeamsStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
                TeamsToConnectStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
            }
        }
    }
}`;

export const updateOffice365Integration = `mutation updateOffice365Integration($input: Office365IntegrationInput!) {
  updateOffice365Integration(input: $input) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
              __typename
            ... on Office365IntegrationType {
                ClientSecret
                ClientId
                TenantId
                SMTP
                Teams
                HasDelegatedSession
                EnabledChangeStatusInConnect
                ConnectToTeamsStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
                TeamsToConnectStatusesMap {
                    statusConnectPath
                    statusTeams
                    editable
                }
            }
        }
    }
}`;

export const createGoogleAuthIntegration = `mutation createGoogleAuthIntegration($input: GoogleAuthIntegrationInput!) {
  createGoogleAuthIntegration(input: $input) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on GoogleAuthIntegrationType {
                  ClientId
                  ClientSecret
                  RefreshToken
            }
        }
    }
}`;

export const updateGoogleAuthIntegration = `mutation updateGoogleAuthIntegration($input: GoogleAuthIntegrationInput!) {
  updateGoogleAuthIntegration(input: $input) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
              __typename
            ... on GoogleAuthIntegrationType {
                  ClientId
                  ClientSecret
                  RefreshToken
            }
        }
    }
}`;

export const createOfficeDynamicsIntegration = `mutation createOfficeDynamicsIntegration($input: OfficeDynamicsIntegrationInput!) {
  createOfficeDynamicsIntegration(input: $input) {
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on OfficeDynamicsIntegrationType {
                Uri
                Version
                AppId
                TenantId
                ClientId
                ClientSecret
                Scope
                RecordingBucketName
                IntegrationSteps {
                  Event
                  Step
                  Active
                  Entity
                  Action
                  RequestBody
                  QueryString
                  Tab
                }
            }
        }
    }
}`;
export const updateOfficeDynamicsIntegration = `mutation updateOfficeDynamicsIntegration($input: OfficeDynamicsIntegrationInput!) {
  updateOfficeDynamicsIntegration(input: $input) {
        Id
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on OfficeDynamicsIntegrationType {
                 Uri
                Version
                AppId
                TenantId
                ClientId
                ClientSecret
                Scope
                RecordingBucketName
                IntegrationSteps {
                  Event
                  Step
                  Active
                  Entity
                  Action
                  RequestBody
                  QueryString
                  Tab
                }
            }
        }
    }
  }`;

export const createAttendeeVideoCallChime = `mutation createAttendeeVideoCallChime($input: commonMeetingIdInput!) {
  createAttendeeVideoCallChime(input: $input) {
    AttendeeId
    Capabilities {
      Audio
      Content
      Video
    }
    ExternalUserId
    JoinToken
    }
}`;

export const createMeetingVideoCallChime = `mutation createMeetingVideoCallChime {
  createMeetingVideoCallChime {
    MeetingId
    ExternalMeetingId
    MediaPlacement {
      AudioFallbackUrl
      AudioHostUrl
      EventIngestionUrl
      ScreenDataUrl
      ScreenSharingUrl
      ScreenViewingUrl
      SignalingUrl
      TurnControlUrl
    }
    MediaRegion
    MeetingArn
    UrlLink
  }
}`;

export const deleteAttendeeVideoCallChime = `mutation deleteAttendeeVideoCallChime($input: deleteAttendeeVideoCallChimeInput!) {
  deleteAttendeeVideoCallChime(input: $input)
}`;

export const deleteMeetingVideoCallChime = `mutation deleteMeetingVideoCallChime($input: commonMeetingIdInput!) {
  deleteMeetingVideoCallChime(input: $input)
}`;

export const startMeetingTranscriptionVideoCallChime = `mutation startMeetingTranscriptionVideoCallChime($input: startMeetingTranscriptionVideoCallChimeInput) {
  startMeetingTranscriptionVideoCallChime(input: $input)
}`;

export const createCustomUrl = `mutation createCustomUrl($input: CustomUrlCreateInput!) {
  createCustomUrl(input: $input) {
    Name
    BaseUrl
    Type
    BrowserTab
    Scope
    AWSAgentID
  }
}`;

export const updateCustomUrl = `mutation updateCustomUrl($input: CustomUrlUpdateInput!) {
  updateCustomUrl(input: $input) {
    message
  }
}`;
export const deleteCustomUrl = `mutation deleteCustomUrl($input: CustomUrlDeleteInput!) {
  deleteCustomUrl(input: $input) {
    message
  }
}`;

export const deployCustomStackIntegration = `mutation deployCustomStackIntegration($input: DeployCustomStackIntegrationInput!) {
  deployCustomStackIntegration(input: $input) {
    id
    version
    deployedVersion
    name
    description
    templateUri
    templateName
    imageLogoUri
    status
    buttons
    outputs {
      name
      description
      value
    }
    templateParameters {
      order
      type
      name
      description
      required
      regex
      options
      value
    }
  }
}`;

export const deleteCustomStackIntegration = `mutation deleteCustomStackIntegration($input: DeleteCustomStackIntegrationInput!) {
  deleteCustomStackIntegration(input: $input) {
    id
    version
    deployedVersion
    name
    description
    templateUri
    templateName
    imageLogoUri
    status
    buttons
    outputs {
      name
      description
      value
    }
    templateParameters {
      order
      type
      name
      description
      required
      regex
      options
      value
    }
  }
}`;

export const updateReportingV2Access = `mutation UpdateReportingV2Access($input: UpdateReportingV2AccessInput!) {
    updateReportingV2Access(input: $input) {
        InstanceId
        SecurityProfileId
        ReportingV2Access {
            DashboardId
        }
    }
}`;

export const transferCustomTask = `mutation transferTask($ContactId: String!, $ConversationId: String, $NewEndpointARN: String!) {
  transferTask(ContactId: $ContactId, ConversationId: $ConversationId, NewEndpointARN:$NewEndpointARN) {
    ContactArn
    ContactId
  }
}`;

export const createAgentChimeMeetingEndpoint = `mutation CreateAgentChimeMeeting($input: CreateMeetingAttributesInput!) {
    createAgentChimeMeeting(input: $input) {
        MeetingId
        ExternalMeetingId
        MediaRegion
        MeetingArn
        InstanceId
        Initiator
        Receiver
        Status
        ContactId
        MediaPlacement {
            AudioFallbackUrl
            AudioHostUrl
            EventIngestionUrl
            ScreenDataUrl
            ScreenSharingUrl
            ScreenViewingUrl
            SignalingUrl
            TurnControlUrl
        }
    }
}`;

export const updateAgentChimeMeetingDetails = `mutation UpdateAgentChimeMeeting($input: UpdateMeetingAttributesInput!) {
    updateAgentChimeMeeting(input: $input) {
        MeetingId
        ExternalMeetingId
        MediaRegion
        MeetingArn
        InstanceId
        Initiator
        Receiver
        Status
        MediaPlacement {
            AudioFallbackUrl
            AudioHostUrl
            EventIngestionUrl
            ScreenDataUrl
            ScreenSharingUrl
            ScreenViewingUrl
            SignalingUrl
            TurnControlUrl
        }
    }
}`;

export const createAttendeeChimeCallEndpoint = `mutation CreateAttendeeChimeMeeting($input: AttendeeMeetingAttributesInput!){
    createAttendeeChimeMeeting(input: $input) {
        AttendeeId
        ExternalUserId
        JoinToken
        Capabilities {
            Audio
            Content
            Video
        }
    }
}`;

export const deleteAgentChimeMeetingEndpoint = `mutation DeleteAgentChimeMeeting($input: DeleteMeetingAttributesInput!) {
    deleteAgentChimeMeeting(input: $input)
}`;

export const cleanupAgentCallStatus = `mutation CleanupAgentCallStatus($input : cleanupAgentCallStatusInput!) {
    cleanupAgentCallStatus(input: $input)
}`;
export const updatePhoneNumber = `mutation updatePhoneNumber($input: PhoneUpdateInput!){
  updatePhoneNumber(input:$input){
    message
  }
}`;

export const updateTrafficGroup = `mutation updateTrafficGroup($input: TrafficGroupUpdateInput!){
  updateTrafficGroup(input:$input){
    message
  }
}`;

export const createTrafficGroup = `mutation createTrafficGroup($input: CreateTrafficGroupInput!){
  createTrafficGroup(input:$input){
    Arn
    Id
  }
}`;

export const addUser2TrafficGroup = `mutation addUser2TrafficGroup($input: AddUser2TrafficGroupInput!){
  addUser2TrafficGroup(input:$input){
    UsersAdded
    UsersSkipped
  }
}`;

export const deleteTrafficGroup = `mutation deleteTrafficGroup($input: DeleteTrafficGroupInput!){
  deleteTrafficGroup(input:$input){
    message
  }
}`;

export const removeUserFromGroup = `mutation removeUserFromGroup($input: RemoveUserFromGroupInput!){
  removeUserFromGroup(input:$input){
    UsersRemoved
    UsersSkipped
  }
}`;

export const createSalesForceIntegration = `mutation createSalesForceIntegration($input: SalesForceIntegrationInput!){
  createSalesForceIntegration(input:$input){
    InstanceId
    Id
    Name
    Type
    Provider
    CreatedBy
    CreatedAt
    UpdatedAt
  }
}`;

export const updateSalesForceIntegration = `mutation updateSalesForceIntegration($input: SalesForceIntegrationInput!){
  updateSalesForceIntegration(input:$input){
    InstanceId
    Name
    Type
    Provider
  }
}`;
